import {
  PostBodyMeasurementRequest,
  PostWorkoutRequestExercise,
  PostWorkoutRequestSet,
  PostWorkoutRequestWorkout,
  RPE,
} from 'hevy-shared';
import API from './API';
import SampleClientAPI from './SampleClientAPI';
import dayjs from 'dayjs';
import { fireAndForget } from './async';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import { v4 as uuid } from 'uuid';

const SAMPLE_PROFILE_PICTURE_URL =
  'https://d2l9nsnmtah87f.cloudfront.net/profile-images/onboarding-4f8f31b5-1d7d-4c6f-a0b9-ff63eedb1557.jpg';

const SAMPLE_PROGRESS_PICTURES = [
  'https://d2l9nsnmtah87f.cloudfront.net/profile-images/onboarding-0072bd1a-217e-49d0-a4d8-6d4c9dc188e9.jpg',
  'https://d2l9nsnmtah87f.cloudfront.net/profile-images/onboarding-c4f18779-62d8-47ee-96d0-c41cf8757f54.jpg',
  'https://d2l9nsnmtah87f.cloudfront.net/profile-images/onboarding-a838ea89-c581-4f76-8f05-6ba415e70eef.jpg',
  'https://d2l9nsnmtah87f.cloudfront.net/profile-images/onboarding-04fae5e3-db8c-48e8-8963-8ea04e66e30c.jpg',
  'https://d2l9nsnmtah87f.cloudfront.net/profile-images/onboarding-98995d10-1da1-46bc-8672-8f3b4de406a8.jpg',
];

const PROGRAM_TITLE = 'Full Body x3';

const ROUTINE_TITLES = ['Full Body Day 1', 'Full Body Day 2', 'Full Body Day 3'];

export const createSampleClient = async (coachUsername: string) => {
  const { auth_token } = (await API.createSampleClient()).data;

  const clients = await API.getClients();

  const sampleClient = clients.data.find(client => {
    return client.is_sample_user === true;
  });

  if (!sampleClient) {
    throw new Error("Can't find sample client after initial creation.");
  }

  SampleClientAPI.setAuthToken(auth_token);

  // Set a profile picture
  await SampleClientAPI.updateAccount({
    profile_pic_url: SAMPLE_PROFILE_PICTURE_URL,
  });

  const routineIds = await generateSampleProgram(sampleClient.id);
  await createSampleWorkouts(coachUsername, routineIds);
  await createSampleMeasurements();
  await fireAndForget([
    memoryStores.clients.fetch().then(() => memoryStores.clients.fetchClientWorkoutHistory()),
    localStorageStores.myPrograms.fetch(),
    memoryStores.myLibraryRoutines.fetch(),
  ]);

  SampleClientAPI.setAuthToken(undefined);
};

const generateSampleProgram = async (clientId: string) => {
  const newProgramId = (
    await API.postProgram({
      title: PROGRAM_TITLE,
      notes:
        'This is a full body 3 day program. You should do it 3 times a week and rest between days.',
      template_program_id: null,
      client_id: clientId,
      folder_id: null,
      duration_days: 28,
      start_date: dayjs().subtract(14, 'days').format('YYYY-MM-DD'),
    })
  ).data;

  const firstRoutineId = (
    await API.createClientRoutine(
      {
        title: ROUTINE_TITLES[0],
        program_id: newProgramId,
        folder_id: null,
        index: 0,
        notes: 'First day of the week, lets go!',
        exercises: [
          {
            // Barbell squat
            exercise_template_id: 'D04AC939',
            rest_seconds: 210,
            sets: [
              { index: 0, weight_kg: 50, reps: 10, indicator: 'warmup' },
              { index: 1, weight_kg: 80, reps: 5, indicator: 'normal' },
              { index: 2, weight_kg: 80, reps: 5, indicator: 'normal' },
              { index: 3, weight_kg: 80, reps: 5, indicator: 'normal' },
            ],
            notes: 'Keep your back straight, focus on form. Aim for RPE 7-8',
          },
          {
            // Barbell bench press
            exercise_template_id: '79D0BB3A',
            rest_seconds: 180,
            sets: [
              { index: 0, weight_kg: 40, reps: 10, indicator: 'warmup' },
              { index: 1, weight_kg: 60, reps: 5, indicator: 'normal' },
              { index: 2, weight_kg: 60, reps: 5, indicator: 'normal' },
              { index: 3, weight_kg: 60, reps: 5, indicator: 'normal' },
            ],
            notes: 'Keep your shoulder blades retracted. Aim for RPE ~8',
          },
          {
            // Barbell row
            exercise_template_id: '55E6546F',
            rest_seconds: 120,
            sets: [
              { index: 0, weight_kg: 40, reps: 8, indicator: 'warmup' },
              { index: 1, weight_kg: 50, reps: 8, indicator: 'normal' },
              { index: 2, weight_kg: 50, reps: 8, indicator: 'normal' },
              { index: 3, weight_kg: 50, reps: 8, indicator: 'normal' },
            ],
            notes: '',
          },
          {
            // Overhead press
            exercise_template_id: '7B8D84E8',
            rest_seconds: 120,
            sets: [
              { index: 0, weight_kg: 30, reps: 10, indicator: 'warmup' },
              { index: 1, weight_kg: 40, reps: 8, indicator: 'normal' },
              { index: 2, weight_kg: 40, reps: 8, indicator: 'normal' },
              { index: 3, weight_kg: 40, reps: 8, indicator: 'normal' },
            ],
            notes: 'Keep your shoulder blades retracted and your elbows tucked in',
          },
          {
            // Lat pulldown
            exercise_template_id: '6A6C31A5',
            rest_seconds: 120,
            sets: [
              { index: 0, weight_kg: 40, reps: 10, indicator: 'normal' },
              { index: 1, weight_kg: 40, reps: 10, indicator: 'normal' },
              { index: 2, weight_kg: 40, reps: 10, indicator: 'normal' },
            ],
            notes: 'Keep your core tight and your elbows tucked in',
          },

          {
            //  Seated leg curl
            exercise_template_id: '11A123F3',
            rest_seconds: 120,
            sets: [
              { index: 0, weight_kg: 30, reps: 10, indicator: 'normal' },
              { index: 1, weight_kg: 30, reps: 10, indicator: 'normal' },
              { index: 2, weight_kg: 30, reps: 10, indicator: 'normal' },
            ],
            notes: '',
          },
        ],
        coach_force_rpe_enabled: false,
      },
      clientId,
    )
  ).data.routineId;

  const secondRoutineId = (
    await API.createClientRoutine(
      {
        title: ROUTINE_TITLES[1],
        program_id: newProgramId,
        folder_id: null,
        index: 1,
        notes: '',
        exercises: [
          {
            // Deadlift
            exercise_template_id: 'C6272009',
            rest_seconds: 210,
            sets: [
              { index: 0, weight_kg: 40, reps: 10, indicator: 'warmup' },
              { index: 1, weight_kg: 70, reps: 5, indicator: 'warmup' },
              { index: 2, weight_kg: 100, reps: 5, indicator: 'normal' },
              { index: 3, weight_kg: 100, reps: 5, indicator: 'normal' },
              { index: 4, weight_kg: 100, reps: 5, indicator: 'normal' },
            ],
            notes:
              'Keep your back straight and your core tight. Feel like you are pushing the ground with your feet! Focus on form. RPE 7-8',
          },
          {
            // Incline bench press dumbbell
            exercise_template_id: '07B38369',
            rest_seconds: 180,
            sets: [
              { index: 0, weight_kg: 20, reps: 10, indicator: 'warmup' },
              { index: 1, weight_kg: 40, reps: 8, indicator: 'normal' },
              { index: 2, weight_kg: 40, reps: 8, indicator: 'normal' },
              { index: 3, weight_kg: 40, reps: 8, indicator: 'normal' },
              { index: 4, weight_kg: 40, reps: 8, indicator: 'normal' },
            ],
            notes: 'Keep your shoulder blades retracted and your elbows tucked in',
          },
          {
            // Pull ups
            exercise_template_id: '1B2B1E7C',
            rest_seconds: 120,
            notes: 'Go to failure on the last set',
            sets: [
              { index: 0, reps: 6, indicator: 'normal' },
              { index: 1, reps: 6, indicator: 'normal' },
              { index: 2, reps: 6, indicator: 'normal' },
              { index: 3, reps: 6, indicator: 'failure' },
            ],
          },
          {
            // Leg extension
            exercise_template_id: '75A4F6C4',
            rest_seconds: 120,
            sets: [
              { index: 0, weight_kg: 30, reps: 10, indicator: 'normal' },
              { index: 1, weight_kg: 30, reps: 10, indicator: 'normal' },
              { index: 2, weight_kg: 30, reps: 10, indicator: 'normal' },
            ],
          },
          {
            // Tricep rope pushdown
            exercise_template_id: '94B7239B',
            rest_seconds: 120,
            sets: [
              { index: 0, weight_kg: 30, reps: 12, indicator: 'normal' },
              { index: 1, weight_kg: 30, reps: 12, indicator: 'normal' },
              { index: 2, weight_kg: 20, reps: 12, indicator: 'failure' },
            ],
          },
          {
            // sit up
            exercise_template_id: '022DF610',
            rest_seconds: 90,
            sets: [
              { index: 0, reps: 15, indicator: 'normal' },
              { index: 1, reps: 15, indicator: 'normal' },
              { index: 2, reps: 15, indicator: 'normal' },
            ],
            notes: 'Squeeze your core!',
          },
        ],
        coach_force_rpe_enabled: false,
      },
      clientId,
    )
  ).data.routineId;

  const thirdRoutineId = (
    await API.createClientRoutine(
      {
        title: ROUTINE_TITLES[2],
        program_id: newProgramId,
        folder_id: null,
        index: 2,
        notes: 'Last day of the week, make sure you get enough rest before the next week',
        exercises: [
          {
            // Barbell squat
            exercise_template_id: 'D04AC939',
            rest_seconds: 180,
            sets: [
              { index: 0, weight_kg: 50, reps: 10, indicator: 'warmup' },
              { index: 1, weight_kg: 65, reps: 8, indicator: 'normal' },
              { index: 2, weight_kg: 65, reps: 8, indicator: 'normal' },
              { index: 3, weight_kg: 65, reps: 8, indicator: 'normal' },
            ],
            notes: 'Keep your back straight and squat below parallel. Aim for RPE 8',
          },
          {
            // Barbell bench press
            exercise_template_id: '79D0BB3A',
            rest_seconds: 180,
            sets: [
              { index: 0, weight_kg: 40, reps: 10, indicator: 'warmup' },
              { index: 1, weight_kg: 50, reps: 8, indicator: 'normal' },
              { index: 2, weight_kg: 50, reps: 8, indicator: 'normal' },
              { index: 3, weight_kg: 50, reps: 8, indicator: 'normal' },
            ],
            notes: 'Keep your shoulder blades retracted and your elbows tucked in',
          },
          {
            // Seated cable row
            exercise_template_id: 'F1D60854',
            rest_seconds: 120,
            sets: [
              { index: 0, weight_kg: 40, reps: 10, indicator: 'normal' },
              { index: 1, weight_kg: 40, reps: 10, indicator: 'normal' },
              { index: 2, weight_kg: 40, reps: 10, indicator: 'normal' },
            ],
            notes: 'Keep your core tight and your elbows tucked in. Aim for RPE 8-9',
          },
          {
            // Bicep curl
            exercise_template_id: '37FCC2BB',
            rest_seconds: 120,
            notes:
              'Aim for RPE 9 and you can push las set to the failure. You should feel your biceps burning!',
            sets: [
              { index: 0, weight_kg: 20, reps: 12, indicator: 'normal' },
              { index: 1, weight_kg: 20, reps: 12, indicator: 'normal' },
              { index: 2, weight_kg: 15, reps: 12, indicator: 'failure' },
            ],
          },
          {
            //  Lateral raise
            exercise_template_id: '422B08F1',
            rest_seconds: 120,
            sets: [
              { index: 0, weight_kg: 15, reps: 15, indicator: 'normal' },
              { index: 1, weight_kg: 15, reps: 15, indicator: 'normal' },
              { index: 2, weight_kg: 10, reps: 15, indicator: 'failure' },
            ],
            notes: '',
          },
          {
            // Face pull
            exercise_template_id: 'BE640BA0',
            rest_seconds: 90,
            notes:
              'Aim for RPE 9 and you can push las set to the failure. You should feel your rear delts burning!',
            sets: [
              { index: 0, weight_kg: 20, reps: 15, indicator: 'normal' },
              { index: 1, weight_kg: 20, reps: 15, indicator: 'normal' },
              { index: 2, weight_kg: 20, reps: 15, indicator: 'normal' },
            ],
          },
        ],
        coach_force_rpe_enabled: false,
      },
      clientId,
    )
  ).data.routineId;

  return [firstRoutineId, secondRoutineId, thirdRoutineId];
};

const generateSampleWithinFraction = (averageValue: number, varience: number): number => {
  const maxValue = averageValue * (1 + varience);
  const minimumValue = averageValue * (1 - varience);
  const range = maxValue - minimumValue;

  const randomFraction = Math.random();
  const randomOffset = Math.floor(randomFraction * range);
  return randomOffset + minimumValue;
};

const generateSampleWeight = (averageWeight: number, varience?: number): number => {
  const maxDifference = varience ?? 5;
  const maxValue = averageWeight + maxDifference;
  const minimumValue = averageWeight - maxDifference;

  const values = [averageWeight, maxValue, minimumValue];

  return values[Math.floor(Math.random() * values.length)];
};

const createSampleWorkouts = async (coachUsername: string, routineIds: (string | undefined)[]) => {
  const DAYS_TO_GENERATE_WORKOUTS_FOR = 14;

  let sampleComments = [
    `Hey @${coachUsername}, how was my form?`,
    `Thanks to @${coachUsername} for a great routine today 🥵`,
    `I think I'm ready to increase my volume @${coachUsername}`,
    '😅',
  ];

  const firstRoutineExercises = [
    generateSampleExercise({
      warmupSets: 2,
      numberOfSets: 3,
      averageReps: 5,
      averageWeightKg: 80,
      templateId: 'D04AC939',
      averageRPE: 7.5,
      title: 'Squat (Barbell)',
    }),
    generateSampleExercise({
      warmupSets: 1,
      numberOfSets: 3,
      averageReps: 5,
      averageWeightKg: 60,
      templateId: '79D0BB3A',
      averageRPE: 8,
      title: 'Bench Press (Barbell)',
    }),
    generateSampleExercise({
      warmupSets: 1,
      numberOfSets: 3,
      averageWeightKg: 50,
      averageReps: 8,
      templateId: '55E6546F',
      averageRPE: 8,
      title: 'Bent Over Row (Barbell)',
    }),
    generateSampleExercise({
      warmupSets: 1,
      numberOfSets: 3,
      averageReps: 8,
      averageWeightKg: 40,
      templateId: '7B8D84E8',
      averageRPE: 8.5,
      title: 'Overhead Press (Barbell)',
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 3,
      averageReps: 10,
      averageWeightKg: 40,
      templateId: '6A6C31A5',
      averageRPE: 8.5,
      title: 'Lat Pulldown (Cable)',
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 3,
      averageReps: 8,
      averageWeightKg: 30,
      templateId: '11A123F3',
      averageRPE: 9,
      title: 'Seated Leg Curl (Machine)',
    }),
  ];

  const secondRoutineExercises = [
    generateSampleExercise({
      warmupSets: 2,
      numberOfSets: 3,
      averageReps: 5,
      averageWeightKg: 100,
      templateId: 'C6272009',
      averageRPE: 7.5,
      title: 'Deadlift (Barbell)',
    }),
    generateSampleExercise({
      warmupSets: 1,
      numberOfSets: 4,
      averageReps: 8,
      averageWeightKg: 40,
      templateId: '07B38369',
      averageRPE: 8.5,
      title: 'Incline Bench Press (Dumbbell)',
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 4,
      averageReps: 6,
      templateId: '1B2B1E7C',
      averageRPE: 8.5,
      title: 'Pull up',
    }),
    generateSampleExercise({
      warmupSets: 1,
      numberOfSets: 3,
      averageReps: 10,
      averageWeightKg: 30,
      templateId: '75A4F6C4',
      averageRPE: 9,
      title: 'Leg Extension (Machine)',
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 3,
      averageReps: 12,
      averageWeightKg: 30,
      templateId: '94B7239B',
      averageRPE: 9,
      title: 'Triceps Rope Pushdown',
      weightFraction: 2.5,
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 3,
      averageReps: 15,
      templateId: '022DF610',
      averageRPE: 9,
      title: 'Sit up',
    }),
  ];

  const thirdRoutineExercises = [
    generateSampleExercise({
      warmupSets: 2,
      numberOfSets: 3,
      averageReps: 8,
      averageWeightKg: 65,
      templateId: 'D04AC939',
      averageRPE: 8,
      title: 'Squat (Barbell)',
    }),
    generateSampleExercise({
      warmupSets: 1,
      numberOfSets: 3,
      averageReps: 8,
      averageWeightKg: 50,
      templateId: '79D0BB3A',
      averageRPE: 8,
      title: 'Bench Press (Barbell)',
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 3,
      averageWeightKg: 40,
      averageReps: 10,
      templateId: 'F1D60854',
      averageRPE: 8.5,
      title: 'Seated Cable Row',
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 3,
      averageReps: 12,
      averageWeightKg: 20,
      templateId: '37FCC2BB',
      averageRPE: 9,
      title: 'Bicep Curl (Dumbbell)',
      weightFraction: 2.5,
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 3,
      averageReps: 15,
      averageWeightKg: 15,
      templateId: '422B08F1',
      averageRPE: 9,
      title: 'Lateral Raise (Dumbbell)',
      weightFraction: 2.5,
    }),
    generateSampleExercise({
      generateFailureSet: true,
      numberOfSets: 4,
      averageReps: 15,
      averageWeightKg: 20,
      templateId: 'BE640BA0',
      averageRPE: 9,
      title: 'Face Pull',
      weightFraction: 2.5,
    }),
  ];

  const routineExercises: PostWorkoutRequestExercise[][] = [
    firstRoutineExercises,
    secondRoutineExercises,
    thirdRoutineExercises,
  ];

  /*
   * Create a workout for each routine
   * Every other day is a rest day
   */
  for (let daysAgo = 0; daysAgo <= DAYS_TO_GENERATE_WORKOUTS_FOR; daysAgo += 2) {
    const workoutStartDate = dayjs()
      .subtract(DAYS_TO_GENERATE_WORKOUTS_FOR - daysAgo, 'days')
      .subtract(generateSampleWithinFraction(3, 0.5), 'hours');

    // Every other workout is a rest day
    // so the program cycle is 6 days since there are 3 routines
    // starting with routine 1
    const routineIndex = (daysAgo / 2) % 3;

    const sampleWorkout: PostWorkoutRequestWorkout = {
      title: ROUTINE_TITLES[routineIndex],
      description: `💪🏻 Workout ${daysAgo / 2 + 1} of the program`,
      media: [],
      start_time: workoutStartDate.unix(),
      end_time: workoutStartDate.add(generateSampleWithinFraction(1, 0.2), 'hour').unix(),
      exercises: routineExercises[routineIndex],
      apple_watch: false,
      routine_id: routineIds[routineIndex],
      wearos_watch: false,
      workout_id: uuid(),
      is_private: false,
    };

    const savedWorkout = await SampleClientAPI.postWorkout({ workout: sampleWorkout });

    const didCommentOnWorkout = Math.random() > 0.5;
    if (!didCommentOnWorkout || sampleComments.length === 0) {
      continue;
    }
    const randomCommentIndex = Math.floor(Math.random() * sampleComments.length);
    const randomComment = sampleComments[randomCommentIndex];

    // Avoid duplicate comments
    sampleComments = sampleComments.filter((_, index) => {
      return index !== randomCommentIndex;
    });

    await SampleClientAPI.postWorkoutComment(savedWorkout.data.id, randomComment);
  }
};

const generateSampleExercise = ({
  generateFailureSet,
  warmupSets,
  numberOfSets,
  averageWeightKg: averageWeight,
  averageReps,
  averageDistance,
  averageDuration,
  averageRPE,
  templateId,
  title,
  weightFraction,
}: {
  warmupSets?: 1 | 2;
  generateFailureSet?: boolean;
  numberOfSets: number;
  averageWeightKg?: number;
  averageReps?: number;
  averageDistance?: number;
  averageDuration?: number;
  averageRPE?: number;
  templateId: string;
  title: string;
  weightFraction?: number;
}): PostWorkoutRequestExercise => {
  const sets: PostWorkoutRequestSet[] = [];

  const warmupSetCount = warmupSets ?? 0;

  // Generate warmup sets
  if (warmupSetCount) {
    sets.push({
      index: 0,
      type: 'warmup',
      // Round to nearest 5kg and 50% of the average weight
      weight_kg: averageWeight
        ? generateSampleWeight(Math.ceil(averageWeight / 2 / 5) * 5, weightFraction)
        : undefined,
      reps: averageReps ? Math.round(generateSampleWithinFraction(averageReps, 0.1)) : undefined,
      distance_meters: averageDistance
        ? Math.round(generateSampleWithinFraction(averageDistance, 0.1))
        : undefined,
      duration_seconds: averageDuration
        ? Math.round(generateSampleWithinFraction(averageDuration, 0.1))
        : undefined,
      rpe: undefined,
    });

    if (warmupSetCount === 2) {
      sets.push({
        index: 1,
        type: 'warmup',
        // Round to nearest 5kg and 75% of the average weight
        weight_kg: averageWeight
          ? generateSampleWeight(Math.ceil(Math.ceil(averageWeight * 0.75) / 5) * 5, weightFraction)
          : undefined,
        reps: averageReps ? Math.round(generateSampleWithinFraction(averageReps, 0.1)) : undefined,
        distance_meters: averageDistance
          ? Math.round(generateSampleWithinFraction(averageDistance, 0.1))
          : undefined,
        duration_seconds: averageDuration
          ? Math.round(generateSampleWithinFraction(averageDuration, 0.1))
          : undefined,
        rpe: undefined,
      });
    }
  }

  // Generate normal sets
  // If we will generate a failure set, we won't generate a normal set for the last set
  for (
    let currentSetIndex = 0;
    currentSetIndex < (generateFailureSet ? numberOfSets - 1 : numberOfSets);
    currentSetIndex += 1
  ) {
    sets.push({
      index: warmupSetCount + currentSetIndex,
      type: 'normal',
      weight_kg: averageWeight ? generateSampleWeight(averageWeight, weightFraction) : undefined,
      reps: averageReps ? Math.round(generateSampleWithinFraction(averageReps, 0.1)) : undefined,
      distance_meters: averageDistance
        ? Math.round(generateSampleWithinFraction(averageDistance, 0.1))
        : undefined,
      duration_seconds: averageDuration
        ? Math.round(generateSampleWithinFraction(averageDuration, 0.1))
        : undefined,
      rpe: averageRPE
        ? ((Math.round(generateSampleWithinFraction(averageRPE, 0.1) * 2) / 2) as RPE)
        : undefined,
    });
  }

  if (generateFailureSet) {
    sets.push({
      index: warmupSetCount + numberOfSets - 1,
      type: 'failure',
      weight_kg: averageWeight ? generateSampleWeight(averageWeight, weightFraction) : undefined,
      reps: averageReps ? Math.round(generateSampleWithinFraction(averageReps, 0.1)) : undefined,
      distance_meters: averageDistance
        ? Math.round(generateSampleWithinFraction(averageDistance, 0.1))
        : undefined,
      duration_seconds: averageDuration
        ? Math.round(generateSampleWithinFraction(averageDuration, 0.1))
        : undefined,
      rpe: 10,
    });
  }

  const exercise: PostWorkoutRequestExercise = {
    title: title,
    exercise_template_id: templateId,
    notes: '',
    sets: sets,
  };

  return exercise;
};

const AVERAGE_BODYWEIGHT_KG = 80;
const AVERAGE_BODY_FAT_PERCENT = 20;
const AVERAGE_NECK_SIZE_CM = 38;
const AVERAGE_SHOULDER_SIZE_CM = 120;
const AVERAGE_CHEST_SIZE_CM = 105;
const AVERAGE_BICEP_SIZE_CM = 35;
const AVERAGE_FOREARM_SIZE_CM = 30;
const AVERAGE_ABDOMEN_SIZE_CM = 90;
const AVERAGE_WAIST_SIZE_CM = 95;
const AVERAGE_HIPS_SIZE_CM = 105;
const AVERAGE_THIGH_SIZE_CM = 60;
const AVERAGE_CALF_SIZE_CM = 40;

const createSampleMeasurements = async () => {
  const DAYS_TO_GENERATE_MEASUREMENTS_FOR = 28;

  for (let daysAgo = 0; daysAgo <= DAYS_TO_GENERATE_MEASUREMENTS_FOR; daysAgo += 7) {
    const measurementDateString = dayjs().subtract(daysAgo, 'days').format('YYYY-MM-DD');
    const weightMultiplier = 1 + daysAgo / (7 * 15);
    const measurement: PostBodyMeasurementRequest = {
      date: measurementDateString,
      picture_url: SAMPLE_PROGRESS_PICTURES[daysAgo / 7],
      weight_kg: generateSampleWithinFraction(AVERAGE_BODYWEIGHT_KG * weightMultiplier, 0.05),
      fat_percent: generateSampleWithinFraction(AVERAGE_BODY_FAT_PERCENT * weightMultiplier, 0.05),
      neck_cm: generateSampleWithinFraction(AVERAGE_NECK_SIZE_CM, 0.01),
      shoulder_cm: generateSampleWithinFraction(AVERAGE_SHOULDER_SIZE_CM, 0.05),
      chest_cm: generateSampleWithinFraction(AVERAGE_CHEST_SIZE_CM, 0.05),
      left_bicep_cm: generateSampleWithinFraction(AVERAGE_BICEP_SIZE_CM, 0.05),
      right_bicep_cm: generateSampleWithinFraction(AVERAGE_BICEP_SIZE_CM, 0.05),
      left_forearm_cm: generateSampleWithinFraction(AVERAGE_FOREARM_SIZE_CM, 0.05),
      right_forearm_cm: generateSampleWithinFraction(AVERAGE_FOREARM_SIZE_CM, 0.05),
      abdomen: generateSampleWithinFraction(AVERAGE_ABDOMEN_SIZE_CM, 0.05),
      waist: generateSampleWithinFraction(AVERAGE_WAIST_SIZE_CM, 0.05),
      hips: generateSampleWithinFraction(AVERAGE_HIPS_SIZE_CM, 0.05),
      left_thigh: generateSampleWithinFraction(AVERAGE_THIGH_SIZE_CM, 0.05),
      right_thigh: generateSampleWithinFraction(AVERAGE_THIGH_SIZE_CM, 0.05),
      left_calf: generateSampleWithinFraction(AVERAGE_CALF_SIZE_CM, 0.05),
      right_calf: generateSampleWithinFraction(AVERAGE_CALF_SIZE_CM, 0.05),
    };
    await SampleClientAPI.postBodyMeasurements(measurement);
  }
};
